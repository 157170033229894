import React from 'react';
import EmailFormCard from '../components/formCard';

function Email() {

    return (
        <div id='pageContent'>
            <h1>
                Email Me
            </h1>
            <EmailFormCard />

        </div>
    )
}

export default Email