import React from 'react';
import MyCarousel from '../components/myCarousel';
function Home() {
    return (
        <div>
            <h1>New Projects</h1>
            <MyCarousel />
        </div>

    );
}

export default Home;