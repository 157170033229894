import React from 'react';
import ProjectCard from '../components/projectcard';

function Projects() {
    return (
        <div>
            <h1 className="projectH1">Projects</h1>
            <div className=''>
                <ProjectCard className="mycard"></ProjectCard>
            </div>
        </div>

    )
}
export default Projects;